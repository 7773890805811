import useCheckClass from "src/hooks/class/useCheckClass";
import ModalPortal from "src/portal/portal";
import * as S from "../style";
import Header from "src/components/common/Header";
import Sidebar from "src/components/common/Sidebar/defaultSideBar";
import Modal from "src/modal";
import UseCheckFloor from "src/hooks/class/useCheckFloor";
import { useEffect, useState } from "react";
import { FIRST_FLOOR_INFO } from "src/constants/room.constants";

const FirstClass = () => {
  const { modal, style, ItemClick, cls } = useCheckClass();
  const { setFloor, classData } = UseCheckFloor();
  const [title, setTitle] = useState<string>("");

  const FloorData = "1";

  useEffect(() => {
    setFloor(FloorData);
  }, []);

  const ListTitle = FIRST_FLOOR_INFO.map((item) => {
    return item.name;
  });

  return (
    <ModalPortal>
      <S.CheckClassWrapper>
        <Header />
        <S.MainWrapper>
          <Sidebar />
          <S.ContentWrapper>
            <S.ContentMainWrapper>
              {FIRST_FLOOR_INFO.map((item, idx) => {
                return (
                  <S.ClassItem
                    key={idx}
                    onClick={() => {
                      item && ItemClick(item.code);
                      setTitle(item.name);
                    }}
                    clicked={style === item.code ? true : false}
                  >
                    <S.ItemContentWrap>{item.name}</S.ItemContentWrap>
                  </S.ClassItem>
                );
              })}
            </S.ContentMainWrapper>
          </S.ContentWrapper>
        </S.MainWrapper>
        {modal === true ? <Modal title={title} onClick={ItemClick} cls={cls}></Modal> : <></>}
      </S.CheckClassWrapper>
    </ModalPortal>
  );
};

export default FirstClass;
