export const FIRST_FLOOR_INFO = [
  {
    code: "1101",
    name: "프로젝트1실",
  },
  {
    code: "1102",
    name: "프로젝트2실",
  },
];

export const SECOND_FLOOR_INFO = [
  {
    code: "2201",
    name: "lab1실",
  },
  {
    code: "2202",
    name: "lab2실",
  },
  {
    code: "2203",
    name: "프로젝트3실",
  },
  {
    code: "2204",
    name: "서버실",
  },
  {
    code: "2205",
    name: "프로젝트5실",
  },
  {
    code: "2206",
    name: "lab3실",
  },
  {
    code: "2207",
    name: "lab4실",
  },
  {
    code: "2208",
    name: "lab5실",
  },
  {
    code: "2209",
    name: "lab6실",
  },
  {
    code: "2210",
    name: "lab7실",
  },
  {
    code: "2211",
    name: "lab8실",
  },
  {
    code: "2212",
    name: "lab9실",
  },
  {
    code: "2213",
    name: "lab10실",
  },
  {
    code: "2214",
    name: "lab11실",
  },
];

export const THIRD_FLOOR_INFO = [
  {
    code: "3301",
    name: "lab12실",
  },
  {
    code: "3302",
    name: "lab13실",
  },
  {
    code: "3303",
    name: "프로젝트5실",
  },
  {
    code: "3304",
    name: "프린터실",
  },
  {
    code: "3305",
    name: "메이커실",
  },
  {
    code: "3306",
    name: "프로젝트6실",
  },
  {
    code: "3307",
    name: "lab14실",
  },
  {
    code: "3308",
    name: "lab15실",
  },
  {
    code: "3309",
    name: "lab16실",
  },
  {
    code: "3310",
    name: "lab17실",
  },
  {
    code: "3311",
    name: "lab18실",
  },
  {
    code: "3312",
    name: "lab19실",
  },
  {
    code: "3313",
    name: "lab20실",
  },
  {
    code: "3314",
    name: "lab21실",
  },
  {
    code: "3315",
    name: "lab22실",
  },
];
